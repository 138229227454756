<template>
<div class="row m-2 p-2 ">

    <head>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
    </head>
    <!-- Modal FOR ADD HSN -->
    <!-- <div v-if="toggleModal">
        <H1>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium expedita rerum dignissimos eos molestiae autem est consequuntur dolor, reiciendis ipsam aliquam eaque possimus, delectus aspernatur, eligendi qui molestias dolorum sequi!
        </H1>

    </div> -->

    <div class="root">
            
        <!-- <Modal v-if="show" @close="closeConfirm">

        </Modal> -->


        <!-- <teleport to='#modal2' > -->
            <!-- <div class="modal" v-if="isOpen">
                <div>
                    <h2>Notification</h2>
                    <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quaerat commodi dolorum voluptatibus fugit ipsam, eum magnam voluptatum mollitia inventore laborum!
                    </p>
                    <button @click="isOpen = false">Close</button>
                </div>
            </div> -->
        <!-- </teleport> -->


        <!-- Modal -->
        <div class="modal fade " style="z-index: 999999;" id="hsn_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog p-0 " role="document">
                <div class="modal-content ">
                    <div class="modal-header">
                        <h3 class="modal-title w-100 text-center" id="exampleModalCenterTitle">{{ this.modal_form_method }} HSN</h3>
                        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button> -->
                    </div>
                    <div class="modal-body">
                        <div class="container py-5">

                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xl">HSN Code</label>
                                <input type="text" class="form-control  px-2 py-1 pe-auto" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter HSN Code" v-model="hsn_form.hsn_code">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-lg">SGST % </label>
                                <input type="text" min="0.00" max="100.00" class="form-control  px-2 py-1 pe-auto" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter SGST (in %)" v-model="hsn_form.sgst">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-sm">CGST %</label>
                                <input type="text" min="0.00" max="100.00" class="form-control px-2 py-1 pe-auto" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter CGST (in %)" v-model="hsn_form.cgst">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xs">IGST %</label>
                                <input type="text" min="0.00" max="100.00" class="form-control px-2 py-1 " id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter IGST (in %)" v-model="hsn_form.igst">
                            </div>
                           
                            
                        </div>
                            
                    </div>
                    <div class="modal-footer justify-content-center">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-on:click="add_hsn(this.modal_form_method)" id="form_btn_add" type="button" data-dismiss="modal" class="btn bg-blue-dark text-white">{{ this.modal_form_method }} HSN</button>
                        <button v-on:click="update_hsn" id="form_btn_update" style="display:none;" type="button" data-dismiss="modal" class="btn bg-blue-dark text-white">Update HSN</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card my-4">
                <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                    <div class="bg-blue-light shadow-success border-radius-lg pt-4 pb-3 row">
                        <h6 class="col-8 text-white text-capitalize ps-3">HSN</h6>
                        <div class="col-4 d-flex justify-content-end align-item-center">
                            <button v-on:click="click_add" data-toggle="modal" data-target="#hsn_modal" class="badge badge-sm bg-white text-info d-flex  align-items-center cursor-pointer ">
                                <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-2  ">add</i>
                                Add HSN

                            </button>
                            <!-- <button @click="isOpen = true" class="badge badge-sm bg-white text-info d-flex  align-items-center cursor-pointer ">
                                <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-2  ">add</i>
                                Add HSN

                            </button> -->
                            <!-- <a-modal v-modal:open="open" title="Basic Modal" @ok="handleok">
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    </a-modal> -->
                        </div>
                    </div>
                </div>
                <div class="card-body px-0 pb-2">
                    <div class="table-responsive p-0">
                        <table class="table align-items-center mb-0" id="data_table_hsn">
                            <thead>
                                <tr>
                                    <th class="text-center text-uppercase text-secondary bg-purple-600 text-sm font-weight-bolder opacity-9">
                                        S.No.
                                    </th>
                                    
                                    <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">
                                        HSN CODE
                                    </th>

                                    <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">
                                        sgst
                                    </th>
                                    <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">
                                        cgst
                                    </th>
                                    <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">
                                        igst
                                    </th>
                                    <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(hsn, index) in all_hsn" :key="hsn.id">
                                    <td class="align-middle text-center">
                                        <span class="text-secondary text-xs font-weight-bold">{{ index +1}}</span>
                                    </td>

                                    <td class="align-middle text-center">
                                        <span class="text-secondary text-xs font-weight-bold">{{ hsn.hsn_code }}</span>
                                    </td>
                                    <td class="align-middle text-center">
                                        <span class="text-secondary text-xs font-weight-bold">{{ hsn.sgst }}</span>
                                    </td>
                                    <td class="align-middle text-center">
                                        <span class="text-secondary text-xs font-weight-bold"> {{ hsn.cgst }}</span>
                                    </td>
                                    <td class="align-middle text-center">
                                        <span class="text-secondary text-xs font-weight-bold">{{ hsn.igst }}</span>
                                    </td>

                                    <td class="align-middle text-center">
                                        <a href="javascript:void(0);" v-on:click="edit_hsn(hsn.id)" class="text-secondary font-weight-bold text-xs" data-toggle="modal" data-target="#hsn_modal">
                                            <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                                            
                                        </a>
                                        <a href="javascript:void(0);" v-on:click="delete_hsn(hsn.id)" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Delete user">
                                            
                                            <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                                        </a>
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {ref} from 'vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap';
// const open = ref(false);

import axios from 'axios';
// import Modal from './modal/modal.vue';


export default {
    setup()
    {
        const isOpen  =  ref(false);
        return {isOpen};

    },  
    data() {
        return {
            // toggleModal: false,
            all_hsn: [
                // {
                //     id: '1',
                //     hsn_id: 'H0001',
                //     hsn_code: '78549',
                //     sgst: '6',
                //     cgst: '6',
                //     igst: '12'
                // },
                // {
                //     id: '2',
                //     hsn_id: 'H0003',
                //     hsn_code: '12345',
                // t: '9',
                //     cgst: '9',
                //     igst: '18'
                // },
                // {
                //     id: '3',
                //     hsn_id: 'H0003',
                //     hsn_code: '54321',
                //     sgst: '2.5',
                //     cgst: '2.5',
                //     igst: '5'
                // },
                // {
                //     id: '4',
                //     hsn_id: 'H0004',
                //     hsn_code: '94682',
                //     sgst: '14',
                //     cgst: '14',
                //     igst: '28'
                // },
            ],
            hsn_form : {
                id : '',
                hsn_code : 'vishal',
                sgst : '',
                cgst : '',
                igst : ''
            },
            modal_form_method:'Add'
        }
    },
    components:[
        // Modal,
    ],
    methods: {
        showModal() {
            console.log('callled')
            this.toggleModal = !this.toggleModal
        },
        click_add()
        {
            // console.log('')
            this.modal_form_method = 'Add';
            this.hsn_form.hsn_code = '';
            this.hsn_form.sgst = '';
            this.hsn_form.cgst = '';
            this.hsn_form.igst = '';
            this.hsn_form.id = '';

        },
        edit_hsn(id)
        {
            // console.log(this.hsn_form);
            this.modal_form_method = 'Update';
            let key = this.all_hsn.find((a)=>
            {
                return a.id==id;
            });
            console.log("key is "+this.all_hsn.indexOf(key));
            key = this.all_hsn.indexOf(key);
            try
            {
                if(key>=0 && key < this.all_hsn.length)
                {

                    const data= this.all_hsn[key];
                    if(data)
                    {
                        console.log("seleced hsn : ", data);
                        this.hsn_form.hsn_code = data.hsn_code;
                        this.hsn_form.cgst = data.cgst;
                        this.hsn_form.sgst = data.sgst;
                        this.hsn_form.igst = data.igst;
                        this.hsn_form.id = data.id;
                        console.log("updated hsn : ", this.all_hsn);
                    }
                    else{
                        console.error("No object found at key:"+key);
                    }
                }   
                else{
                    console.error("invalid key:"+key);
                }
            }
            catch(error)
            {

                console.error("Error updating HSN form:", error);
            }
            
        },
        async delete_hsn(id)
        {
            console.log(`https://arogya.softwarewabi.com/backend/public/api/hsndelete/`+id);
            let delete_result = await axios.delete(`https://arogya.softwarewabi.com/backend/public/api/hsndelete/`+id);
            console.log(delete_result.data.result);
            this.get_hsn();
        },
        async get_hsn()
        {
            let result = await axios.get('https://arogya.softwarewabi.com/backend/public/api/hsnshow');
            console.log(result.data.hsn);
            console.warn(this.all_hsn);
            this.all_hsn = result.data.hsn;
            console.warn(this.all_hsn);
        },
        async add_hsn(method)
        {
            if(method=='Add')
            {
                
                let result = await axios.post('https://arogya.softwarewabi.com/backend/public/api/hsnstore', this.hsn_form);
                console.log(result.data);
                this.get_hsn();
            }
            else if(method == 'Update')
            {
                let result = await axios.put(`https://arogya.softwarewabi.com/backend/public/api/hsnupdate/${this.hsn_form.id}`, this.hsn_form);
                console.log(result.data);
                this.get_hsn();

            }
        }
        
    },
    async mounted(){
      this.get_hsn();
    }

}
</script>

<style>
.root {
    position: relative;
}

.modal {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.1);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9990;
}

.modal > div {
    background-color: #fff;
    padding: 50px;
    border-radius: 10px;
    z-index: 999;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.5rem 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: #495057;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #d2d6da !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: 0.2s ease;
}
.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25) !important;
}
</style>

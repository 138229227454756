<template>
    <div class="row m-2 p-2">
      <head>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
      </head>
      <!-- Modal FOR ADD HSN -->
      <!-- <div v-if="toggleModal">
        <H1>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium expedita rerum dignissimos eos molestiae autem est consequuntur dolor, reiciendis ipsam aliquam eaque possimus, delectus aspernatur, eligendi qui molestias dolorum sequi!
        </H1>
    
    </div> -->
      

      <!-- Modal -->
      <div class="modal fade " style="z-index: 999999;" id="vendor_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog p-0 " role="document">
                <div class="modal-content ">
                    <div class="modal-header">
                        <h3 class="modal-title w-100 text-center" id="exampleModalCenterTitle">{{ this.modal_form_method }} Vendor</h3>
                        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button> -->
                    </div>
                    <div class="modal-body">
                        <div class="container py-5">

                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xl">Vendor Name</label>
                                <input type="text" class="form-control  px-2 py-1 pe-auto" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Vendor Name" v-model="vendor_form.party_name">
                            </div>

                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xl">GST Number</label>
                                <input type="text" class="form-control  px-2 py-1 pe-auto" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter GST Number" v-model="vendor_form.gst_no">
                            </div>

                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xl">PAN Number</label>
                                <input type="text" class="form-control  px-2 py-1 pe-auto" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter PAN Number" v-model="vendor_form.pan_no">
                            </div>

                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xl">Address </label>
                                <input type="text" class="form-control  px-2 py-1 pe-auto" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Address" v-model="vendor_form.address">
                            </div>
                            
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-sm">Email</label>
                                <input type="text" class="form-control px-2 py-1 pe-auto" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" v-model="vendor_form.email">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xs">Number</label>
                                <input type="text" class="form-control px-2 py-1 " id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Number" v-model="vendor_form.contact">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xs">Account No</label>
                                <input type="text" class="form-control px-2 py-1 " id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account No" v-model="vendor_form.bank_details.ac_no">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xs">IFSC code</label>
                                <input type="text" class="form-control px-2 py-1 " id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter IFSC cod" v-model="vendor_form.bank_details.ifsc_code">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xs">Branch Name</label>
                                <input type="text" class="form-control px-2 py-1 " id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Branch Name" v-model="vendor_form.bank_details.branch_name">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xs">Branch Address</label>
                                <input type="text" class="form-control px-2 py-1 " id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Address" v-model="vendor_form.bank_details.branch_address">
                            </div>
                           
                            
                        </div>
                            
                    </div>
                    <div class="modal-footer justify-content-center">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-on:click="add_vendor(this.modal_form_method)" id="form_btn_add" type="button" data-dismiss="modal" class="btn bg-blue-dark text-white">{{ this.modal_form_method }} Vendor


                        </button>
                        <button v-on:click="update_hsn" id="form_btn_update" style="display:none;" type="button" data-dismiss="modal" class="btn bg-blue-dark text-white">Update Vendor</button>
                    </div>
                </div>
            </div>
        </div>
  
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-blue-light shadow-success border-radius-lg pt-4 pb-3 row"
              >
                <h6 class="col-8 text-white text-capitalize ps-3">Vendor</h6>
                <div class="col-4 d-flex justify-content-end align-item-center">
                 
                  <button v-on:click="click_add" data-toggle="modal" data-target="#vendor_modal" class="badge badge-sm bg-white text-info d-flex  align-items-center cursor-pointer ">
                      <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-2  ">person_add</i>
      
                      Add Vendor

                  </button>
                        
        
                  <!-- <a-modal v-modal:open="open" title="Basic Modal" @ok="handleok">
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                  </a-modal> -->
                </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        class="text-center  text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        S.No.
                      </th>
                      <th
                        class="text-center  text-uppercase text-secondary text-sm font-weight-bolder opacity-9 ps-2"
                      >
                        Name
                      </th>
                      <th
                        class="text-center thead text-uppercase  text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Address
                      </th>
                      <th
                        class="text-center thead text-uppercase  text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Bank Details
                      </th>
                      <th
                        class="text-center  text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Vendor Id
                      </th>
                      
                      <th
                        class="text-center  text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Email
                      </th>
                      <th
                        class="text-center  text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Number
                      </th>
                      <th class="text-center  text-uppercase text-secondary text-sm font-weight-bolder opacity-9">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(vendor, index) in all_vendors" :key="vendor.id">
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ index + 1 }}</span
                        >
                      </td>
                      
                      <td class="align-middle text-center">
                        <h3  class="mb-0 text-xl   text-sm "> {{ vendor.party_name }}</h3>
                          <div class="row justify-content-center">
                            <span class="text-sm "><strong>GST No : </strong>{{ vendor.gst_no }}</span>
                            <span class="text-sm "><strong>PAN No : </strong>{{ vendor.pan_no }}</span>
                            

                          </div>
                        
                        
                      </td> 
                      <td class="align-middle text-center">
                          <span class="text-sm text-secondary text-wrap "><strong>Address : </strong>{{ vendor.address }}</span>

                      </td> 

                      <td class="align-middle text-center">
                        <!-- <h3  class="mb-0 text-xl   text-sm "> {{ vendor.party_name }}</h3> -->
                          <div class="row justify-content-center ">
                            
                            <span class="text-sm text-wrap"><strong>Acc No : </strong>{{ vendor.bank_details}}</span>
                            <!-- <span class="text-sm "><strong>IFSC Code : </strong>{{ vendor.bank_details?.ifsc_code || '' }}</span>
                            <span class="text-sm "><strong>Branch Name: </strong>{{ vendor.bank_details?.branch_name || '' }}</span>
                            <span class="text-sm "><strong>Branch Address: </strong>{{ vendor.bank_details?.branch_address || '' }}</span> -->
                            

                          </div>
                        
                        
                      </td> 

                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold border border-secondary py-1 px-2"
                          >{{ vendor.vendor_id }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ vendor.email }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          > {{ vendor.contact }}</span
                        >
                      </td> 
                      
                      <td class="align-middle text-center">
                        <a
                          href="javascript:void(0);"
                          class="text-secondary font-weight-bold text-xs"
                          v-on:click="edit_vendor(vendor.id)"
                          data-toggle="modal"
                          data-target="#vendor_modal"
                        >
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                          
                        </a>
                        <a
                          href="javascript:void(0);"
                          class="text-secondary font-weight-bold text-xs"
                          v-on:click="delete_vendor(vendor.id)"
                          data-toggle="tooltip"
                          data-original-title="Edit user"
                        >
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                      
                        </a>
                      </td>
                    </tr>
                    
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
  </template>
  

<script>
import axios from 'axios';
export default {
    name:'Vendor',
    data(){
        return {
            all_vendors:[
                
            ],
            vendor_form : {
              id:'',
              party_name : '',
              gst_no : '',
              pan_no : '',
              address : '',
              email : '',
              contact : '',
              bank_details : {
                ac_no : '',
                ifsc_code : '',
                branch_name : '',
                branch_address : '' 
              }
            },
            modal_form_method : 'Add'
        }
    },
    methods:{
      click_add()
      {
        console.log('click_add called');
        this.modal_form_method = 'Add';
  

      },
      edit_vendor()
      {
          // console.log(this.hsn_form);
          this.modal_form_method = 'Update';
          
          
      },
      async add_vendor(method)
      {
        if(method == 'Add')
        {
          console.log('add');
          let result = await axios.post('https://arogya.softwarewabi.com/backend/public/api/Vendor_insert', this.vendor_form);
          console.log(result.data);
          this.get_vendor();
          
        }
        else if(method == 'Update')
        {
          console.log(`https://arogya.softwarewabi.com/backend/public/api/Vendor_update/${this.vendor_form.id}`);
          let result = await axios.put(`https://arogya.softwarewabi.com/backend/public/api/Vendor_update/${this.vendor_form.id}`, this.vendor_form);
          console.log(result.data);
          this.get_vendor();

        }
      },
      async delete_vendor(id)
      {
        let result = await axios.delete(`https://arogya.softwarewabi.com/backend/public/api/Vendor_delete/${id}}`);
        console.log(result.data.result, id);
        this.get_vendor();
      },
      async get_vendor()
      {
        let result = await axios.get(`https://arogya.softwarewabi.com/backend/public/api/Vendor_show`);
        console.log(result.data);
        this.all_vendors = result.data.vendor;
      },
    },
    async mounted(){
      this.get_vendor();
    }
}
</script>

<style>

.form-control {
    display: block;
    width: 100%;
    padding: 0.5rem 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: #495057;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #d2d6da !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: 0.2s ease;
}
.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25) !important;
}

.thead{
  width: 15rem;
}

</style>
<template>
  <div class="row m-2 p-2">
    <head>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
    </head>
    


<!-- Modal -->
<div class="modal fade " style="z-index: 999999;" id="user_modal" tabindex="-1" role_name="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog p-0 " role_name="document">
                <div class="modal-content ">
                    <div class="modal-header">
                        <h3 class="modal-title w-100 text-center" id="exampleModalCenterTitle">{{ this.modal_form_method }} User</h3>
                        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button> -->
                    </div>
                    <div class="modal-body">
                        <div class="container py-5">

                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xl">Name</label>
                                <input v-model="user_form.name" type="text" class="form-control  px-2 py-1 pe-auto" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Name" >
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-lg">Email</label>
                                <input v-model="user_form.email" type="email" class="form-control  px-2 py-1 pe-auto" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Email" >
                            </div>

                            <div class="p-2" v-if="this.modal_form_method == 'Add'">
                                <label for="exampleInputEmail1 text-lg">Password</label>
                                <input v-model="user_form.password" type="text" class="form-control  px-2 py-1 pe-auto" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter password" >
                            </div>
                           
                            <!-- <div class="p-2">
                                <label for="exampleInputEmail1">User Role</label>
                                <input v-model="user_form.role_name" type="text" class="form-control px-2 py-1 pe-auto" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter User Role" >
                            </div> -->
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1">Roles</label>
                                <select class="form-control px-2 py-1" v-model="user_form.role_id">
                                  <option value="">Select Roles</option>
                                  <option v-for="role in all_roles" :key="role.id" :value="role.id">
                                    {{ `${role.name}` }}
                                  </option>
                                </select>
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1">Franchises</label>
                                <select class="form-control px-2 py-1" v-model="user_form.franchise_id">
                                  <option value="">Select Franchise</option>
                                  <option v-for="franchise in all_franchise" :key="franchise.id" :value="franchise.id">
                                    {{ `${franchise.party_name}, ${franchise.location} (${franchise.type})` }}
                                  </option>
                                  <!-- <option value="arogya">Aarogya(admin)</option>
                                  <option value="master_franchise">Master Franchise</option>
                                  <option value="franchise">Franchise</option> -->
                                </select>
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1">Contact Number</label>
                                <input v-model="user_form.contact_no" type="text" class="form-control px-2 py-1 " id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter IGST (in %)" >
                            </div>
                           
                            
                        </div>
                            
                    </div>
                    <div class="modal-footer justify-content-center">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-on:click="add_user(this.modal_form_method)" id="form_btn_add" type="button" data-dismiss="modal" class="btn bg-blue-dark text-white">{{ this.modal_form_method }} User</button>
                        <button v-on:click="update_user" id="form_btn_update" style="display:none;" type="button" data-dismiss="modal" class="btn bg-blue-dark text-white">Update User</button>
                    </div>
                </div>
            </div>
        </div>
    

      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-blue-light shadow-success border-radius-lg pt-4 pb-3 row"
            >
              <h6 class="col-8 text-white text-capitalize ps-3">Users Management</h6>
              <div class="col-4 d-flex justify-content-end align-item-center">
                <button v-on:click="click_add" data-toggle="modal" data-target="#user_modal" class="badge badge-sm bg-white text-info d-flex  align-items-center cursor-pointer ">
                            
                    <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-2  ">person_add</i>

                    Add User
                    
                </button>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                    >
                      S.No.
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9 ps-2"
                    >
                      Name
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                    >
                      User ID
                    </th>
                    
                    <th
                      class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                    >
                      Role
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                    >
                      Register Type
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                    >
                      Number
                    </th>
                    <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user, index) in all_users" :key="user.id">
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >{{ index+1 }}</span
                      >
                    </td>
                    <td>
                      <div class="d-flex px-2 py-1">
                        
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">{{ user.name}}</h6>
                          <p class="text-xs text-secondary mb-0">
                            {{ user.email }}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >{{ user.user_id }}</span
                      >
                    </td> <td>
                      <p class="text-xs font-weight-bold mb-0">{{ user.role_name }}</p>
                      <p class="text-xs text-secondary mb-0">Organization</p>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <span :class="franchise_type_class(user.franchise_name)"
                        >{{ user.franchise_name }}</span
                      >
                    </td>
                   
                   
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >{{ user.contact_no }}</span
                      >
                    </td>
                    <td class="align-middle text-center">
                      <a
                        href="javascript:;"
                        class="text-secondary font-weight-bold text-xs"
                        data-toggle="modal"
                        data-target="#user_modal"
                        v-on:click="edit_user(user.id)"
                      >
                        <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                       
                      </a>
                      <a
                        href="javascript:;"
                        class="text-secondary font-weight-bold text-xs"
                        v-on:click="delete_user(user.id)"
                      >
                        <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                    
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >1</span
                      >
                    </td>
                    <td>
                      <div class="d-flex px-2 py-1">
                        <!-- <div>
                          <img
                            src="../assets/img/team-2.jpg"
                            class="avatar avatar-sm me-3 border-radius-lg"
                            alt="user1"
                          />
                        </div> -->
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">John Michael</h6>
                          <p class="text-xs text-secondary mb-0">
                            john@creative-tim.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >A00001</span
                      >
                    </td> <td>
                      <p class="text-xs font-weight-bold mb-0">Manager</p>
                      <p class="text-xs text-secondary mb-0">Organization</p>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <span class="badge badge-sm bg-gradient-info"
                        >Aarogya</span
                      >
                    </td>
                   
                   
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >23/04/18</span
                      >
                    </td>
                    <td class="align-middle text-center">
                      <a
                        href="javascript:;"
                        class="text-secondary font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="Edit user"
                      >
                        <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                        <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                    
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >1</span
                      >
                    </td>
                    <td>
                      <div class="d-flex px-2 py-1">
                        <!-- <div>
                          <img
                            src="../assets/img/team-3.jpg"
                            class="avatar avatar-sm me-3 border-radius-lg"
                            alt="user2"
                          />
                        </div> -->
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">Alexa Liras</h6>
                          <p class="text-xs text-secondary mb-0">
                            alexa@creative-tim.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >A00002</span
                      >
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">Programator</p>
                      <p class="text-xs text-secondary mb-0">Developer</p>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <span class="badge badge-sm bg-gradient-secondary"
                        >RetailFranchise</span
                      >
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >11/01/19</span
                      >
                    </td>
                    <td class="align-middle text-center">
                      <a
                        href="javascript:;"
                        class="text-secondary font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="Edit user"
                      >
                        <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                        <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                    
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >1</span
                      >
                    </td>
                    <td>
                      <div class="d-flex px-2 py-1">
                        <!-- <div>
                          <img
                            src="../assets/img/team-4.jpg"
                            class="avatar avatar-sm me-3 border-radius-lg"
                            alt="user3"
                          />
                        </div> -->
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">Laurent Perrier</h6>
                          <p class="text-xs text-secondary mb-0">
                            laurent@creative-tim.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >A00003</span
                      >
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">Executive</p>
                      <p class="text-xs text-secondary mb-0">Projects</p>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <span class="badge badge-sm bg-gradient-success"
                        >MasterFranchise</span
                      >
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >19/09/17</span
                      >
                    </td>
                    <td class="align-middle text-center">
                      <a
                        href="javascript:;"
                        class="text-secondary font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="Edit user"
                      >
                      <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                        <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                    
                      </a>  
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >1</span
                      >
                    </td>
                    <td>
                      <div class="d-flex px-2 py-1">
                        <!-- <div>
                          <img
                            src="../assets/img/team-3.jpg"
                            class="avatar avatar-sm me-3 border-radius-lg"
                            alt="user4"
                          />
                        </div> -->
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">Michael Levi</h6>
                          <p class="text-xs text-secondary mb-0">
                            michael@creative-tim.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >A00004</span
                      >
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">Programator</p>
                      <p class="text-xs text-secondary mb-0">Developer</p>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <span class="badge badge-sm bg-gradient-success"
                        >MasterFranchise</span
                      >
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >24/12/08</span
                      >
                    </td>
                    <td class="align-middle text-center">
                      <a
                        href="javascript:;"
                        class="text-secondary font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="Edit user"
                      >
                        <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                        <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                    
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >1</span
                      >
                    </td>
                    <td>
                      <div class="d-flex px-2 py-1">
                        <!-- <div>
                          <img
                            src="../assets/img/team-2.jpg"
                            class="avatar avatar-sm me-3 border-radius-lg"
                            alt="user5"
                          />
                        </div> -->
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">Richard Gran</h6>
                          <p class="text-xs text-secondary mb-0">
                            richard@creative-tim.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >A00005</span
                      >
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">Manager</p>
                      <p class="text-xs text-secondary mb-0">Executive</p>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <span class="badge badge-sm bg-gradient-secondary"
                        >RetailFranchise</span
                      >
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >04/10/21</span
                      >
                    </td>
                    <td class="align-middle text-center">
                      <a
                        href="javascript:;"
                        class="text-secondary font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="Edit user"
                      >
                        <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                        <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                    
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >1</span
                      >
                    </td>
                    <td>
                      <div class="d-flex px-2 py-1">
                        <!-- <div>
                          <img
                            src="../assets/img/team-4.jpg"
                            class="avatar avatar-sm me-3 border-radius-lg"
                            alt="user6"
                          />
                        </div> -->
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">Miriam Eric</h6>
                          <p class="text-xs text-secondary mb-0">
                            miriam@creative-tim.com
                          </p>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >A00006</span
                      >
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">Programator</p>
                      <p class="text-xs text-secondary mb-0">Developer</p>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <span class="badge badge-sm bg-gradient-secondary"
                        >RetailFranchise</span
                      >
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >14/09/20</span
                      >
                    </td>
                    <td class="align-middle text-center">
                      <a
                        href="javascript:;"
                        class="text-secondary font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="Edit user"
                      >
                        <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                        <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                    
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name:'UserManagement',
  data(){
    return {
      all_franchise : [

      ],
      all_roles : [

      ],
      all_users:[
        {
          sno:'1',
          name:'aladin',
          user_id:'A00001',
          franchise_name:'Aarogya',
          role_name:'central_admin',
          email:'admin@gmail.com',
          contact_no:'565487321',
          // image:'team-2.jpg'
          image:'../assets/img/team-2.jpg'  
        },
        {
          sno:'2',
          name:'shimoka',
          user_id:'A00002',
          franchise_name:'MasterFranchise',
          role_name:'central_admin',
          email:'mf_chimok@gmail.com',
          contact_no:'7874681',
          image:''
        },
        {
          sno:'2',
          name:'shimoka',
          user_id:'A00002',
          franchise_name:'RetailFranchise',
          role_name:'central_admin',
          email:'rf_chimok@gmail.com',
          contact_no:'7874681',
          image:''
        },
        {
          sno:'3',
          name:'goku',
          user_id:'A00003',
          franchise_name:'MasterFranchise',
          role_name:'central_admin',
          email:'mf_goku@gmail.com',
          contact_no:'47856878541',
          image:''
        },
        {
          sno:'4',
          name:'larau',
          user_id:'A00004',
          franchise_name:'RetailFranchise',
          role_name:'central_admin',
          email:'rf_laru@gmail.com',
          contact_no:'9521475558',
          image:''
        },
        {
          sno:'5',
          name:'pucher',
          user_id:'A00005',
          franchise_name:'RetailFranchise',
          role_name:'central_admin',
          email:'rf_pucher@gmail.com',
          contact_no:'349685217',
          image:''
        },
      ],
      user_form : {
        id:'',
        name : '',
        email : '',
        role_name : '',
        role_id : '',
        franchise_name : '',
        contact_no : '',
        franchise_id : '',
        password : ''
      },
      modal_form_method : 'Add',
    }
  },
  methods:
  {
    click_add()
    {
      this.modal_form_method = 'Add';
      this.user_form.name = '';
      this.user_form.email = '';
      this.user_form.role_name = '';
      this.role_id  = '';
      this.password = '';
      this.user_form.franchise_name = '';
      this.user_form.contact_no = '';
      this.user_form.franchise_id = '';
    },
    edit_user(id)
    {
      this.modal_form_method = 'Update';
      delete this.user_form.password;
      let key = this.all_users.find((a) => 
      {
          return a.id == id;
      });
      key = this.all_users.indexOf(key);
      try
      {
        if(key >= 0 && key < this.all_users.length)
        {
          const data = this.all_users[key];
          if(data)
          {
            console.log(`selected user : ${data}`);
            this.user_form.name = data.name;
            this.user_form.email = data.email;
            this.user_form.role_name = data.role_name;
            this.user_form.role_id = data.role_id;
            this.user_form.franchise_name = data.franchise_name;
            this.user_form.franchise_id = data.franchise_id;
            this.user_form.contact_no = data.contact_no;
            this.user_form.id = data.id;
          } 
          else
          {
            console.error( `no object found at key ${key}`);
          }
        }
        else 
        {
          console.error(`invalid key : ${key}`);
        }
      }
      catch(error)
      {
        console.log(`error updating user form ${error}`);
      }
    },
    async delete_user(id)
    {
      let result = await axios.delete(`https://arogya.softwarewabi.com/backend/public/api/Usersdelete/${id}`);
      console.log(result.data);
      this.get_user();
    },
    async add_user(method)
    {
      if(method=='Add')
      {
        let result = await axios.post('https://arogya.softwarewabi.com/backend/public/api/Usersinsert', this.user_form);
        console.log(result.data); 
        this.get_user();
      }
      else if(method == 'Update')
      {
        let result = await axios.put(`https://arogya.softwarewabi.com/backend/public/api/Usersupdate/${this.user_form.id}`, this.user_form);
        console.log(result.data);
        this.get_user();
      }
    },
    async get_user()
    {
      let result = await axios.get('https://arogya.softwarewabi.com/backend/public/api/Usersshow');
      console.log(result.data);
      this.all_users = result.data.users;
    },
    async get_franchise()
    {
      let result = await axios.get(`https://arogya.softwarewabi.com/backend/public/api/franchiseshow`);
      console.log(result.data);
      this.all_franchise = result.data.franchises;
    },
    async get_roles()
    {
      let result = await axios.get(`https://arogya.softwarewabi.com/backend/public/api/roleshow`);
      console.log(result.data);
      this.all_roles = result.data.role;
    },
    getImageUrl (imagePath){
      return require('@/assets/img/'+imagePath);
    },
    franchise_type_class (item){
      // console.log(item)
      // return item
      const cl = {
        MasterFranchise : 'badge badge-lg bg-gradient-secondary' ,
        master_franchise : 'badge badge-lg bg-gradient-secondary' ,
        RetailFranchise : 'badge badge-lg bg-gradient-success' ,
        franchise : 'badge badge-lg bg-gradient-success' ,
        Aarogya : 'badge badge-lg bg-gradient-info' ,
        arogya : 'badge badge-lg bg-gradient-info' ,
        item: 'badge badge-lg bg-gradient-primary'
      }



      console.log(item, cl[item], cl.item);
      // return (item=='MasterFranchise')?cl.MasterFranchise: (item=='RetailFranchise')?cl.RetailFranchise:(item=='Aarogya')?cl.Aarogya:'';
      return cl[item];
    }

  },
  async mounted()
  {
    this.get_user();
    this.get_franchise();
    this.get_roles();
  },
  computed: {
  }
}
</script>

<style>

</style>
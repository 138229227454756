<template>
    <div class="row m-2 p-2">
      <head>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
      </head>
      <!-- Modal FOR ADD HSN -->
      <!-- <div v-if="toggleModal">
        <H1>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium expedita rerum dignissimos eos molestiae autem est consequuntur dolor, reiciendis ipsam aliquam eaque possimus, delectus aspernatur, eligendi qui molestias dolorum sequi!
        </H1>
    
    </div> -->
      
    <!-- Modal -->
    <div class="modal fade " style="z-index: 999999;" id="medicine_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog p-0 " role="document">
                <div class="modal-content ">
                    <div class="modal-header">
                        <h3 class="modal-title w-100 text-center" id="exampleModalCenterTitle">{{ this.modal_form_method }} Medicine</h3>
                        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button> -->
                    </div>
                    <div class="modal-body">
                        <div class="container py-5">

                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xl">Medicine Name</label>
                                <input type="text" class="form-control  px-2 py-1 pe-auto" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Medicine Name" v-model="medicine_form.name">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-lg">Medicine Salt </label>
                                <input type="text"  class="form-control  px-2 py-1 pe-auto" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Medicine Salt" v-model="medicine_form.salt">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-sm">Brand Name</label>
                                <input type="text"  class="form-control px-2 py-1 pe-auto" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Brand Name" v-model="medicine_form.brand">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xs">Medicine Group</label>
                                <input type="text"  class="form-control px-2 py-1 " id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Medicine Group" v-model="medicine_form.medicine_group">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xs">HSN</label>
                             
                                <select class="form-control px-2 py-1" id="select_hsn" v-model="medicine_form.hsn">
                                  <option value="">Select HSN</option>
                                  <option v-for="hsn in all_hsn" :key="hsn.id" 
                                   v-bind:value="hsn.id" >
                                    {{ hsn.hsn_code }}
                                  </option>
                                </select>
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xs">Category</label>
                                <input type="text"  class="form-control px-2 py-1 " id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Medicine Category" v-model="medicine_form.category">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xs">Note</label>
                                <input type="text"  class="form-control px-2 py-1 " id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Note" v-model="medicine_form.note">
                            </div>
                           
                            
                        </div>
                            
                    </div>
                    <div class="modal-footer justify-content-center">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-on:click="add_medicine(this.modal_form_method)" id="form_btn_add" type="button" data-dismiss="modal" class="btn bg-blue-dark text-white">{{ this.modal_form_method }} Medicine</button>
                        <button v-on:click="update_hsn" id="form_btn_update" style="display:none;" type="button" data-dismiss="modal" class="btn bg-blue-dark text-white">Update Medicine</button>
                    </div>
                </div>
            </div>
        </div>

  
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-blue-light shadow-success border-radius-lg pt-4 pb-3 row"
              >
                <h6 class="col-8 text-white text-capitalize ps-3">Medicine</h6>
                <div class="col-4 d-flex justify-content-end align-item-center">
                  <button v-on:click="click_add" data-toggle="modal" data-target="#medicine_modal" class="badge badge-sm bg-white text-info d-flex  align-items-center cursor-pointer ">
                                
                        <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-2  ">add</i>
                        Add Medicine
                        
                  </button>
             
                </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        S.No.
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9 ps-2"
                      >
                        Medicine
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Salt
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9 ps-2"
                      >
                        Brand name
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9 ps-2"
                      >
                        Packing
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Type
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        HSN
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Note
                      </th>
                      
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        medicine_group
                      </th>
                      
                      <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(medicine, index) in all_medicines" :key="medicine.id">
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ index + 1 }} 
                          <!-- {{ medicine.id }} -->
                          </span
                        >
                      </td>
                      
                      <td class="align-middle text-center">
                        <!-- <h6 class="mb-0 text-sm inline"> {{ medicine.type.type }}</h6> -->
                        <h5 class="mb-0 text-sm inline"> {{ medicine.name }}</h5>
                        <p class="text-xs text-secondary mb-0">
                            {{ medicine.category }}
                        </p>
                       
                        <!-- <p class="text-xs text-secondary mb-0">
                            {{ medicine.packing }}
                        </p>
                        <p class="text-xs text-secondary mb-0">
                            {{ medicine.Packing }}
                        </p> -->
                        
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ medicine.salt  }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ medicine.brand }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        brand
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >type</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ medicine.hsn }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ medicine.note }}</span
                        >
                      </td> 
                     
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          > {{ medicine.medicine_group }}</span
                        >
                      </td> 
                      
                      
                      <td class="align-middle text-center">
                        <a
                          href="javascript:;"
                          class="text-secondary font-weight-bold text-xs"
                          data-toggle="modal"
                          data-target="#medicine_modal"
                          v-on:click="edit_medicine(medicine.id)"
                        >
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                      
                        </a>
                        <a
                          href="javascript:;"
                          class="text-secondary font-weight-bold text-xs"
                          v-on:click="delete_medicine(medicine.id)"
                        >
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                      
                        </a>
                      </td>
                    </tr>
                    
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
  </template>
  

<script>
import axios from 'axios';
export default {
    name: 'Medicine',
    data() {
        return {
          all_hsn : [
            {}
          ],
            all_medicines:[
                // {
                //     id:'1',
                //     name:'Paracetamol',
                //     salt:'Paracetamol-500',
                //     brand:'Calpol',
                //     hsn:'',
                //     medicine_group:'Analgesic | Antipyretic',
                //     medicine_id:'M00001',
                //     note:'',
                //     category:'Tablet',
                    
                // },
            ],
            medicine_form : {
              id:'',
              name:'',
              salt:'',
              brand:'',
              medicine_group:'',
              hsn : '',
              note:'',
              category:'',
            },
            modal_form_method : 'Add',
        }
    },
    methods:{
      click_add()
      {
        console.log('click_add called');
        this.modal_form_method = 'Add';
        this.medicine_form.id = '';
        this.medicine_form.name = '';
        this.medicine_form.salt = '';
        this.medicine_form.brand = '';
        this.medicine_form.medicine_group = '';
        this.medicine_form.hsn = '';
        this.medicine_form.note = '';
        this.medicine_form.category = ''
      },
      edit_medicine(id)
      {
        this.modal_form_method = 'Update';
        let key = this.all_medicines.find((a) => 
        {
          return a.id == id;
        });
        key = this.all_medicines.indexOf(key);
        try
        {
          if(key>=0 && key < this.all_medicines.length)
          {
            const data = this.all_medicines[key];
            if(data)
            {
              this.medicine_form.id = data.id;
              this.medicine_form.name = data.name;
              this.medicine_form.salt = data.salt;
              this.medicine_form.brand =  data.brand;
              this.medicine_form.medicine_group = data.medicine_group;
              this.medicine_form.hsn =  data.hsn_id;
              this.medicine_form.note = data.note;
              this.medicine_form.category = data.category;
            } 
            else
            {
              console.error("no object found at key : "+key);
            }
          }
          else 
          {
            console.error("invalid key : "+key);
          }
        }
        catch(error)
        {
          console.error("Error updating HSN form" , error);
        }
      },
      async add_medicine(method)
      {
        if(method == 'Add')
        {
          console.log('add');
          console.log(this.medicine_form);
          let result = await axios.post('https://arogya.softwarewabi.com/backend/public/api/medicinestore', this.medicine_form);
          console.log(result.data);
          this.get_medicine();
        }
        else if(method == 'Update')
        {
          console.log('update', `https://arogya.softwarewabi.com/backend/public/api/medicineupdate/${this.medicine_form.id}`);
          let result = await axios.put(`https://arogya.softwarewabi.com/backend/public/api/medicineupdate/${this.medicine_form.id}`, this.medicine_form);
          console.log(result.data);
          this.get_medicine();
        }
      },
      async delete_medicine(id)
      {
        console.log(`https://arogya.softwarewabi.com/backend/public/api/medicinedelete/${id}`);
        let result = await axios.delete(`https://arogya.softwarewabi.com/backend/public/api/medicinedelete/${id}`);
        console.log(result.data);
        this.get_medicine();
      },
      async get_medicine()
      {
        let result = await axios('https://arogya.softwarewabi.com/backend/public/api/medicineshow');
        this.all_medicines = result.data.medicine;
      },
      async get_hsn()
      {
        let result = await axios.get('https://arogya.softwarewabi.com/backend/public/api/hsnshow');
        console.log(result.data.hsn);
        console.warn(this.all_hsn);
        this.all_hsn = result.data.hsn;
        console.warn(this.all_hsn);
      }
    },
    async mounted()
    {
      this.get_medicine();
      this.get_hsn();

    }
}
</script>

<style>

 
/* ::placeholder{
  color: !important;
} */
                                
.form-control {
    display: block;
    width: 100%;
    padding: 0.5rem 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: #495057;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #d2d6da !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: 0.2s ease;
}
.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25) !important;
}

</style>
<template>
  <!-- <h2>this is arogya purchase form</h2> -->
  <div class="row m-2 p-2 ">

<head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
</head>
<!-- Modal FOR ADD HSN -->
<!-- <div v-if="toggleModal">
    <H1>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium expedita rerum dignissimos eos molestiae autem est consequuntur dolor, reiciendis ipsam aliquam eaque possimus, delectus aspernatur, eligendi qui molestias dolorum sequi!
    </H1>

</div> -->

<div class="root">
        
    <!-- <Modal v-if="show" @close="closeConfirm">

    </Modal> -->


    <!-- <teleport to='#modal2' > -->
        <!-- <div class="modal" v-if="isOpen">
            <div>
                <h2>Notification</h2>
                <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quaerat commodi dolorum voluptatibus fugit ipsam, eum magnam voluptatum mollitia inventore laborum!
                </p>
                <button @click="isOpen = false">Close</button>
            </div>
        </div> -->
    <!-- </teleport> -->



    <div class="col-12">
        <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                <div class="bg-blue-light shadow-success border-radius-lg pt-4 pb-3 row">
                    <h6 class="col-8 text-white text-capitalize ps-3">Arogya Purchase Form</h6>
                    <div class="col-4 d-flex justify-content-end align-item-center">
                        
                        <!-- <button @click="isOpen = true" class="badge badge-sm bg-white text-info d-flex  align-items-center cursor-pointer ">
                            <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-2  ">add</i>
                            Add HSN

                        </button> -->
                        <!-- <a-modal v-modal:open="open" title="Basic Modal" @ok="handleok">
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                </a-modal> -->
                    </div>
                </div>
            </div>
            <div class="card-body px-0 pb-2">
                <div class="table-responsive p-0">
                    
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>
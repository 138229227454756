<template>
<div class="row m-2 p-2">

    <head>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
    </head>
    <!-- Modal FOR ADD HSN -->
    <!-- <div v-if="toggleModal">
        <H1>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium expedita rerum dignissimos eos molestiae autem est consequuntur dolor, reiciendis ipsam aliquam eaque possimus, delectus aspernatur, eligendi qui molestias dolorum sequi!
        </H1>

    </div> -->

    <div class="col-12">
        <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                <div class="bg-blue-light shadow-success border-radius-lg pt-4 pb-3 row">
                    <h6 class="col-8 text-white text-capitalize ps-3">Sales Entry</h6>
                    <div class="col-4 d-flex justify-content-end align-item-center">
                        <span class="badge badge-sm bg-white text-info d-flex  align-items-center cursor-pointer ">

                            <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-2  ">add</i>
                            Add Sales

                        </span>
                        <!-- <a-modal v-modal:open="open" title="Basic Modal" @ok="handleok">
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                  </a-modal> -->
                    </div>
                </div>
            </div>
            <div class="card-body px-0 pb-2">
                <div class="table-responsive p-0">
                    <table class="table align-items-center mb-0">
                        <thead>
                            <tr>
                                <th class="text-uppercase text-secondary text-sm font-weight-bolder opacity-9">
                                    S.No.
                                </th>
                                <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9 ps-2">
                                    Medicine Name
                                </th>

                                <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">
                                    Franchise Details
                                </th>
                                <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">
                                    Invoice No
                                </th>
                                <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">
                                    Quotation Id
                                </th>
                                <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">
                                    Discount 
                                </th>
                                <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">
                                    GST
                                </th>
                                <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">
                                    ShipRocket Id
                                </th>
                                <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">
                                    Amount
                                </th>

                                <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="sales in all_sales" :key="sales.sno">
                                <td class="align-middle text-center">
                                    <span class="text-secondary text-xs font-weight-bold">{{ sales.sno }}</span>
                                </td>

                                <td class="align-left text-left">
                                    <h6 class="mb-0 text-sm inline"><b>Medicine:</b> {{ sales.medicine_name }}</h6>
                                    <p class="mb-0 text-sm inline"><b>Quantity:</b> {{ sales.quantity }}</p>
                                    <p class="mb-0 text-sm inline"><b>Expiry Date:</b> {{ sales.expiry_date }}</p>
                                    <p class="mb-0 text-sm inline"><b>Batch No:</b> {{ sales.batch_no }}</p>
                                    <p class="mb-0 text-sm inline"><b>MRP:</b> {{ sales.mrp }}</p>

                                </td>
                                <td class="align-middle  text-center row">
                                    <div>

                                        <span :class="franchise_details_class(sales.register_type)">{{ sales.register_type }}</span>
                                    </div>
                                    <div class="my-2">

                                        <span class="mb-0 text-sm  px-2 py-1 border border-2  w-auto">{{ sales.store_name }}</span>
                                    </div>
                                </td>
                                <td class="align-middle text-center">
                                    <span class="text-secondary text-xs font-weight-bold  ">{{ sales.invoice_number }}</span>
                                </td>

                                <td class="align-middle text-center">
                                    <span class="text-secondary text-xs font-weight-bold"> {{ sales.quotation_id }}</span>
                                </td>
                                <td class="align-middle text-center">
                                    <p class="text-secondary text-xs mb-1 "> <b> Amount(in Rs): </b> {{ sales.total_amount }}</p>
                                    <p class="text-secondary text-xs mb-1 "> <b> Discount(in %): </b> {{ sales.discount_percentage }}</p>
                                    <p class="text-secondary text-xs mb-1 "> <b> Discount(in Rs): </b> {{ sales.discount_rupees  }}</p>
                                    <p class="text-secondary text-xs mb-1 "> <b> Total Amount(in Rs): </b> {{ parseInt(sales.total_amount) - parseInt(sales.discount_rupees) }}</p>
                                </td>
                                <td class="align-middle text-center">
                                   
                                    <p class="text-secondary text-xs mb-1 "> <b> GST(in %): </b> {{ sales.gst_percentage }}</p>
                                    <p class="text-secondary text-xs mb-1 "> <b> GST(in Rs): </b> {{ (parseInt(sales.gst_percentage))/100*parseInt(sales.total_amount - sales.discount_rupees) }}</p>
                                    <p class="text-secondary text-xs mb-1 "> <b> Grand Total(in Rs): </b> {{ (100+parseInt(sales.gst_percentage))/100*parseInt(sales.total_amount - sales.discount_rupees) }}</p>
                                </td>
                                <td class="align-middle text-center">
                                    <span class="text-secondary text-xs font-weight-bold"> Total Amount(in Rs): {{ sales.total_amount }}</span>
                                </td>
                                <td class="align-middle text-center">
                                    <p class="text-secondary text-xs mb-1 "> <b> Grand Total(in Rs): </b> {{ sales.grand_total }}</p>
                                    <p class="text-secondary text-xs mb-1 "> <b> Paid Amount(in Rs): </b> {{ sales.paid_amount }}</p>
                                    <p class="text-secondary text-xs  mb-1"> <b> Due Amount(in Rs): </b> {{ sales.due_amount }}</p>
                                </td>

                                <td class="align-middle text-center">
                                    <a href="javascript:;" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                        <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                                        <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>

                                    </a>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'Stocks',
    data() {
        return {
            all_sales: [{
                    sno: '1',
                    medicine_name: 'Paracetamol',
                    quantity: '200',
                    expiry_date: '21/04/2025',
                    batch_no: 'B-0193',
                    mrp: '25',
                    register_type: 'Master Franchise',
                    store_name: 'ABC',
                    invoice_number: 'INV00001',
                    quotation_id: 'Q0000001',
                    sales_entry_date: '06/06/2024',
                    total_amount: '1000',
                    discount_gst: '-%',
                    discount_rupees: '100',
                    discount_percentage: '10',
                    gst_percentage: '18',
                    shiprocket_id: 'S000001',
                    grand_total: '1062',
                    paid_amount: '1000',
                    due_amount: '62',
                    margin: '20%'
                },

            ]
        }
    },
    methods: {
        franchise_details_class(item) {
            console.log(item);
            return item.toLowerCase() == 'aarogya' ? 'badge badge-lg bg-gradient-info' : item.toLowerCase() == 'master franchise' ? 'badge badge-lg bg-gradient-secondary' : item.toLowerCase() == 'retail franchise' ? 'badge badge-lg bg-gradient-success' : ''
        }
    },
    async mounted()
    {
        let result = await axios.get('http://localhost:3001/sales');
        this.all_sales = result.data;   
    }
}
</script>

<style>

</style>

<template>
<div class="row m-2 p-2">

  <head>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
  </head>

  <!-- form Modal -->
  <div class="modal  modal-fullscreen-sm-down modal-dialog-scrollable fade " style="z-index: 999999;" id="purchase_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog  modal-xl p-0 " role="document">
      <div class="modal-content ">
        <div class="modal-header">
          <h3 class="modal-title w-100 text-center" id="exampleModalCenterTitle">{{ this.modal_form_method }} Purchase</h3>
        </div>
        <div class="modal-body">

          <div class="row mx-4 mt-2">

            <div class="col-2 ">
              <div class="form-group">

                <label for="medicine"> Invoice Number</label>
                <input type="text" class="form-control px-2" name="p_invoice_nunber" v-model="purchase_form.invoice_number" readonly>
              </div>
            </div>
            <div class="col-2 ">
              <div class="form-group">

                <label for="medicine">Vendor Invoice Number</label>
                <input type="text" class="form-control px-2" name="v_invoice_number" v-model="purchase_form.vendor_invoice">
              </div>
            </div>
            <div class="col-2 ">
              <div class="form-group">

                <label for="vendor">Vendor</label>
                <!-- <input type="text" class="form-control px-2">  -->
                <select name="vendor" id="vendor" class="form-control px-2" v-model="purchase_form.vendor_id">
                  <option value="">Select Vendor</option>
                  <option v-for="(vendor, index) in all_vendors" :key="index" :value="vendor.id" class="px-2">
                    {{ vendor.party_name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-3 mb-3 mt-4 d-flex justify-content-start align-content-center">
              <button v-on:click="click_add" class="badge badge-sm bg-blue-light text-white d-flex  align-items-center cursor-pointer ">

                <i class="material-icons-round opacity-10 fs-6  text-info bg-white p-1 rounded-circle mx-2  ">add</i>
                Add Medicine
              </button>
            </div>

          </div>
          <div class="row mx-4 mt-2">
            <div class="col-2 ">
              <div class="form-group">

                <label for="medicine">Medicine</label>

              </div>
            </div>
            <div class="col-2 ">
              <div class="form-group">

                <label for="medicine">Salt</label>
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label for="medicine">Brand Name</label>
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label for="batch">MRP</label>
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label for="batch">Batch No</label>
              </div>
            </div>
            <div class="col-2 ">
              <div class="form-group">

                <label for="expiry_date">Expiry Date</label>
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label for="medicine">Discount</label>
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label for="medicine">Quantity</label>
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label for="ttl_amt"><small>Total Amount</small></label>
              </div>
            </div>
          </div>
          <div class="row mx-4" v-for="(row, index) in medicine_rows" :key="index">
            <div class="col-2 ">
              <div class="form-group">

                <!-- <label v-if="index==0" for="medicine">Medicine</label> -->
                <!-- <input type="text" class="form-control px-2">  -->
                <select name="medicine" id="medicine" class="form-control px-2" v-on:input="fill_fields(index)" v-model="row.medicine_id">
                  <option value="">Select Medicine</option>
                  <option v-for="(medicine, index) in all_medicines" :key="index" :value="medicine.id" class="px-2" :m-salt="medicine.salt" :m-brand="medicine.brand">
                    {{ `${medicine.name},${medicine.salt},${medicine.brand}` }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-2 ">
              <div class="form-group">

                <!-- <label v-if="index==0" for="medicine" class="mb-3">Salt</label> -->
                <input type="text" class="form-control px-2" name="salt" readonly>
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <!-- <label v-if="index==0" for="medicine">Brand Name</label> -->
                <input type="text" class="form-control px-2" name="brand" readonly>
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <!-- <label v-if="index==0" for="batch">MRP</label> -->
                <input type="text" class="form-control px-2" name="mrp" v-model="row.mrp" v-on:input="calculate_total(index)">
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <!-- <label v-if="index==0" for="batch">Batch No</label> -->
                <input type="text" class="form-control px-2" name="batch" v-model="row.batch_no">
              </div>
            </div>
            <div class="col-2 ">
              <div class="form-group">

                <!-- <label v-if="index==0" for="expiry_date">Expiry Date</label> -->
                <input type="date" class="form-control px-2" v-model="row.expiry_date">
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <!-- <label v-if="index==0" for="medicine">Discount</label> -->
                <input type="text" class="form-control px-2" v-model="row.discount" v-on:input="calculate_total(index)">
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <!-- <label v-if="index==0" for="medicine">Quantity</label> -->
                <input type="text" class="form-control px-2" v-model="row.qty" v-on:input="calculate_total(index)">
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <!-- <label v-if="index==0" for="ttl_amt"><small>Total Amount</small></label> -->
                <input type="text" class="form-control px-2" id="ttl_amt" v-model="row.total_amount" disabled>
              </div>
            </div>
            <div class="col-1" v-if="medicine_rows.length > 1" v-on:click="delete_row(index)">
              <i class="material-icons-round opacity-10 fs-8  text-danger bg-white p-1 rounded-circle mx-2  ">delete</i>

            </div>
          </div>

        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button v-on:click="add_purchase(this.modal_form_method)" id="form_btn_add" type="button" data-dismiss="modal" class="btn bg-blue-dark text-white">{{ this.modal_form_method }} Purchase</button>
        </div>
      </div>
    </div>
  </div>

  <!-- View Modal -->
  <div class="modal  modal-fullscreen-sm-down modal-dialog-scrollable fade " style="z-index: 999999;" id="view_purchase_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog  modal-xl p-0 " role="document">
      <div class="modal-content ">
        <div class="modal-header">
          <h3 class="modal-title w-100 text-center" id="exampleModalCenterTitle">{{ this.modal_form_method }} Purchase</h3>
        </div>
        <div class="modal-body">
          
          <div class="row mx-4 mt-2">
            <div class="col-2 ">
              <div class="form-group">

                <label for="medicine">Medicine</label>

              </div>
            </div>
            <div class="col-2 ">
              <div class="form-group">

                <label for="medicine">Salt</label>
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label for="medicine">Brand Name</label>
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label for="batch">MRP</label>
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label for="batch">Batch No</label>
              </div>
            </div>
            <div class="col-2 ">
              <div class="form-group">

                <label for="expiry_date">Expiry Date</label>
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label for="medicine">Discount</label>
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label for="medicine">Quantity</label>
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label for="ttl_amt"><small>Total Amount</small></label>
              </div>
            </div>
          </div>

          
          <div class="row mx-4 mt-2" v-for="(purchase, index) in all_purchases[view_form_id]" :key="index">
            <div class="col-2 ">
              <div class="form-group">

                <label for="medicine">{{purchase.medicine_id}}</label>

              </div>
            </div>
            <div class="col-2 ">
              <div class="form-group">

                <label for="medicine">{{ purchase.salt }}</label>
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label for="medicine">{{ purchase.brand }}</label>
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label for="batch">{{ purchase.mrp }}</label>
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label for="batch">{{ purchase.batch_no }}</label>
              </div>
            </div>
            <div class="col-2 ">
              <div class="form-group">

                <label for="expiry_date">{{ purchase.expiry_date }}</label>
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label for="medicine">{{ purchase.discount }}</label>
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label for="medicine">{{ purchase.quantity }}</label>
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label for="ttl_amt"><small>Total Amount</small></label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
         
        </div>
      </div>
    </div>
  </div>

  <div class="col-12" v-if="is_purchasing">
    <div class="card my-4">
      <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
        <div class="bg-blue-light shadow-success border-radius-lg pt-4 pb-3 row">
          <h6 class="col-8 text-white text-capitalize ps-3">Arogya Purchase Form</h6>
          <div class="col-4 d-flex justify-content-end align-item-center">
            <button v-on:click="this.is_purchasing=false" class="mx-1 px-4 badge bade-xl bg-danger text-white d-flex  align-items-center cursor-pointer ">
              Close
            </button>
            <button v-on:click="click_add" class="mx-1 px-4 badge bade-xl bg-success text-white d-flex  align-items-center cursor-pointer ">
              Submit
            </button>

          </div>
        </div>
      </div>
      <div class="card-body px-0 pb-2">
        <div class="table-responsive p-0">
          <div class="row">
            <div class="col-8">

              <h3 class="mx-4 ">Purchase Form</h3>
            </div>
            <!-- <div class="col-3 d-flex justify-content-end align-content-center">
                  <button v-on:click="click_add"  class="badge badge-sm bg-white text-info d-flex  align-items-center cursor-pointer ">

                              <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-2  ">add</i>
                              Add
                    </button>
                </div> -->

          </div>
          <div class="row mx-4 mt-2">

            <div class="col-2 ">
              <div class="form-group">

                <label for="medicine">Purchase Invoice Number</label>
                <input type="text" class="form-control px-2" name="p_invoice_nunber" v-model="purchase_form.invoice_number" readonly>
              </div>
            </div>
            <div class="col-2 ">
              <div class="form-group">

                <label for="medicine">Vendor Invoice Number</label>
                <input type="text" class="form-control px-2" name="v_invoice_number" v-model="purchase_form.vendor_invoice">
              </div>
            </div>
            <div class="col-2 ">
              <div class="form-group">

                <label for="vendor">Vendor</label>
                <!-- <input type="text" class="form-control px-2">  -->
                <select name="vendor" id="vendor" class="form-control px-2" v-model="purchase_form.vendor_id">
                  <option value="">Select Vendor</option>
                  <option v-for="(vendor, index) in all_vendors" :key="index" :value="vendor.id" class="px-2">
                    {{ vendor.party_name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-3 my-3 d-flex justify-content-start align-content-center">
              <button v-on:click="click_add" class="badge badge-sm bg-blue-light text-white d-flex  align-items-center cursor-pointer ">

                <i class="material-icons-round opacity-10 fs-6  text-info bg-white p-1 rounded-circle mx-2  ">add</i>
                Add Medicine
              </button>
            </div>

          </div>
          <div class="row mx-4 mt-2" v-for="(row, index) in medicine_rows" :key="row.id">
            <div class="col-2 ">
              <div class="form-group">

                <label v-if="index==0" for="medicine">Medicine</label>
                <!-- <input type="text" class="form-control px-2">  -->
                <select name="medicine" id="medicine" class="form-control px-2" v-on:input="fill_fields(index)">
                  <option value="">Select Medicine</option>
                  <option v-for="(medicine, index) in all_medicines" :key="index" :value="medicine.id" class="px-2" :m-salt="medicine.salt" :m-brand="medicine.brand">
                    {{ `${medicine.name},${medicine.salt},${medicine.brand}` }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-2 ">
              <div class="form-group">

                <label v-if="index==0" for="medicine">Salt</label>
                <input type="text" class="form-control px-2" name="salt" readonly>
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label v-if="index==0" for="medicine">Brand Name</label>
                <input type="text" class="form-control px-2" name="brand" readonly>
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label v-if="index==0" for="batch">MRP</label>
                <input type="text" class="form-control px-2" name="mrp">
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label v-if="index==0" for="batch">Batch No</label>
                <input type="text" class="form-control px-2" name="batch">
              </div>
            </div>
            <div class="col-2 ">
              <div class="form-group">

                <label v-if="index==0" for="expiry_date">Expiry Date</label>
                <input type="date" class="form-control px-2">
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label v-if="index==0" for="medicine">Discount</label>
                <input type="text" class="form-control px-2">
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label v-if="index==0" for="medicine">Quantity</label>
                <input type="text" class="form-control px-2">
              </div>
            </div>
            <div class="col-1 ">
              <div class="form-group">

                <label v-if="index==0" for="ttl_amt"><small>Total Amount</small></label>
                <input type="text" class="form-control px-2" id="ttl_amt">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12">
    <div class="card my-4">
      <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
        <div class="bg-blue-light shadow-success border-radius-lg pt-4 pb-3 row">
          <h6 class="col-8 text-white text-capitalize ps-3">Purchases</h6>
          <div class="col-4 d-flex justify-content-end align-item-center">
            <!-- <a href="/arogya-purchase-form" >
                    <button v-on:click="click_add" data-toggle="" data-target="#purchase_modal" class="badge badge-sm bg-white text-info d-flex  align-items-center cursor-pointer ">

                          <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-2  ">add</i>
                          Add Purchase
                    </button>
                  </a> -->
            <!-- <router-link :to="{ name : 'Arogya Purchase'}"> -->
            <button v-on:click="click_add" data-target="#purchase_modal" data-toggle="modal" class="badge badge-sm bg-white text-info d-flex  align-items-center cursor-pointer ">

              <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-2  ">add</i>
              Add Purchase
            </button>
            <!-- </router-link> -->

          </div>
        </div>
      </div>
      <div class="card-body px-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-sm font-weight-bolder opacity-9">
                  S.No.
                </th>

                <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">
                  Purchase Invoice No
                </th>
                <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">
                  Vendor Invoice No
                </th>

                <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">
                  Vendor Name
                </th>
                <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">
                  Total Amount
                </th>

                <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(purchase, index) in all_purchases" :key="index">
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{ index+1 }}</span>
                </td>

                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold border border-secondary py-1 px-2">{{ purchase.invoice_number }}</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"> {{ purchase.vendor_invoice }}</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{ purchase.vendor_id }}</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"> {{ purchase.total_amount }}</span>
                </td>

                <td class="align-middle text-center">
                  <a href="javascript:;" class="text-secondary font-weight-bold text-xs" data-toggle="modal" data-target="#view_purchase_modal" v-on:click="this.view_form_id = index">
                    <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">visibility</i>

                  </a>
                  <a href="javascript:;" class="text-secondary font-weight-bold text-xs" v-on:click="delete_purchase(purchase.id)">
                    <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>

                  </a>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Purchase',
  data() {
    return {
      all_purchases: [{
          id: '1',
          user_id: '',
          purchase_id: 'P00001',
          purchase_invoice_no: 'PI00001',
          vendor_name: 'Vendor1',
          total_amount: '1000',
          vendor_invoice_no: '7854126',
          invoice_no: 'INV0001'
        },

      ],
      all_medicines: [

      ],
      all_vendors: [

      ],
      medicine_rows: [{
        medicine_id: '',
        // salt : '',
        // brand : '',
        qty: '',
        batch_no: '',
        mrp: '',
        expiry_date: '',
        discount: '',
        total_amount: '',
      }],
      purchase_form: {
        id: '',
        user_id: '2',
        pi: {

        },
        medicine_id: [],
        qty: [],
        vendor_id: '',
        batch_no: [],
        mrp: [],
        expiry_date: [],
        discount: [],
        total_amount: '',
        vendor_invoice: '',
        invoice_number: '',
        user_name: '',
        medicine_name: []
      },
      modal_form_method: 'Add',
      is_purchasing: false,
      view_form_id : -1,
      form_row: 0,
    }
  },
  methods: {
    fill_fields(id) {
      let medicines = document.querySelectorAll('select[name="medicine"]');
      let salts = document.querySelectorAll('input[name="salt"]');
      let brands = document.querySelectorAll('input[name="brand"]');
      let medicine = medicines[id];
      console.log(salts[id]);
      console.log(brands[id]);
      console.log(medicine);
      salts[id].value = medicine.options[medicine.selectedIndex].getAttribute('m-salt');
      brands[id].value = medicine.options[medicine.selectedIndex].getAttribute('m-brand');

    },
    click_add() {
      // this.is_purchasing = true;
      this.medicine_rows.push({
        medicine_id: '',
        qty: '',
        batch_no: '',
        mrp: '',
        expiry_date: '',
        discount: '',

      });
      this.form_row++;
    },
    calculate_total(i) {
      var mrp = this.medicine_rows[i].mrp;
      var discount = this.medicine_rows[i].discount;
      var qty = this.medicine_rows[i].qty;
      if (parseInt(discount) > 100) {
        this.medicine_rows[i].discount = 100;
      }
      if (parseInt(mrp) >= 0 && parseInt(discount) >= 0 && parseInt(qty) >= 0) {

        this.medicine_rows[i].total_amount = mrp * (100 - discount) / 100 * qty;
      }
    },
    alter_purchase_form() {
      console.log('alter purchase form called');
      let grand_total = 0;
      this.medicine_rows.forEach((mr) => {
        console.log(mr);
        console.log(mr.medicine_id, this.purchase_form.medicine);
        this.purchase_form.medicine_id.push(mr.medicine_id);
        this.purchase_form.qty.push(mr.qty);
        this.purchase_form.expiry_date.push(mr.expiry_date);
        this.purchase_form.discount.push(mr.discount);
        grand_total += mr.total_amount;
      });
      this.purchase_form.user_id = 1;
      this.purchase_form.total_amount = grand_total;
    },
    async add_purchase() {
      this.alter_purchase_form();

      let result = await axios.post(`https://arogya.softwarewabi.com/backend/public/api/Arogya_purchase_insert`, this.purchase_form);
      console.log(result.data);
      this.get_purchases();
    },
    async delete_purchase(id) {
      let result = await axios.delete(`https://arogya.softwarewabi.com/backend/public/api/Arogya_purchase_delete/${id}`);
      console.log(result.data);
      this.get_purchases();
    },
    async get_purchases() {
      let result = await axios.get('https://arogya.softwarewabi.com/backend/public/api/Arogya_purchase_show');
      this.all_purchases = result.data.arogya_purchase;
      console.log(this.all_purchases.length);
      console.log(this.all_purchases);

      this.generate_invoice_number();
    },
    async get_medicines() {
      let result = await axios.get(`https://arogya.softwarewabi.com/backend/public/api/medicineshow`);
      this.all_medicines = result.data.medicine;
    },
    async get_vendors() {
      let result = await axios.get(`https://arogya.softwarewabi.com/backend/public/api/Vendor_show`);
      this.all_vendors = result.data.vendor;
    },
    generate_invoice_number() {
      // console.log('length', this.all_purchases.length);
      // console.log(this.all_purchases[this.all_purchases.length-1].invoice_number);
      let last_invoice_no = this.all_purchases[this.all_purchases.length - 1].invoice_number;
      let new_invoice_no = "INV-00" + parseInt(parseInt(last_invoice_no.substring(5)) + 1);
      this.purchase_form.invoice_number = new_invoice_no;
    },
    delete_row(i) {

      console.log('delete row called', i);
      this.medicine_rows.splice(i, 1);
    }
  },
  async mounted() {
    this.get_purchases();
    this.get_medicines();
    this.get_vendors();
  }
}
</script>

<style>

</style>

<template>
    <div class="row m-2 p-2">
      <head>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
      </head>
    <!--Quotation Add and Update Modal Start-->
    <div class="modal fade " style="z-index: 999999;" id="quote_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog p-0 " role="document">
                <div class="modal-content ">
                    <div class="modal-header">
                        <h3 class="modal-title w-100 text-center" id="exampleModalCenterTitle">{{ this.modal_form_method }} Quotation</h3>
                    </div>
                    <div class="modal-body">
                        <div class="container py-5">
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xl">Sender Name</label>
                                <select class="form-control px-2 py-1" id="select_sender" v-model="quotations_form.from_id">
                                  <option value="">Select Sender</option>
                                  <option v-for="vendor in all_vendor" :key="vendor.id" :value="vendor.id">
                                    {{ vendor.party_name }}
                                  </option>
                                </select>
                            </div>                           
                                
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-sm">Reciever Name</label>
                                <select class="form-control px-2 py-1" id="select_reciever" v-model="quotations_form.to_id">
                                  <option value="">Select Reciever</option>
                                  <option value=1>Arogya</option>
                                  <option value=2>Master Franchise</option>
                                  <option value=3>Franchise</option>
                                </select>
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xs">Purchase Order Id</label>
                                <input type="text" class="form-control px-2 py-1 " id="exampleInputEmail1" aria-describedby="emailHelp" v-model="quotations_form.po">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xs">Purchase Invoice</label>
                                <input type="text"  class="form-control px-2 py-1 " id="exampleInputEmail1" aria-describedby="emailHelp" v-model="quotations_form.pi">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xs">Purchase Invoice No</label>
                                <input type="text"  class="form-control px-2 py-1 " id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Purchase Invoice No" v-model="quotations_form.pi_no">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xs">Note</label>
                                <input type="text"  class="form-control px-2 py-1 " id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Note" v-model="quotations_form.reason">
                            </div>
                           
                        </div>
                            
                    </div>
                    <div class="modal-footer justify-content-center">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-on:click="add_quote(this.modal_form_method)" id="form_btn_add" type="button" data-dismiss="modal" class="btn bg-blue-dark text-white">{{ this.modal_form_method }} Quotation</button>
                    </div>
                </div>
            </div>
        </div>
      <!--Quotation Add and Update Modal End-->

      <!--Quotation Delete Modal Start-->
      <!-- <div class="modal fade" id="deleteModal" style="z-index: 999999;" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog p-0" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <h4>Are you sure you want to delete?</h4>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
              <button type="button" class="btn btn-primary" v-on:click="delete_quote()">Yes</button>
            </div>
          </div>
        </div>
      </div> -->
      <!--Quotation Delete Modal End-->

  
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-blue-light shadow-success border-radius-lg pt-4 pb-3 row"
              >
                <h6 class="col-8 text-white text-capitalize ps-3">Quotation</h6>
                <div class="col-4 d-flex justify-content-end align-item-center">
                    <button v-on:click="click_add" data-toggle="modal" data-target="#quote_modal" class="badge badge-sm bg-white text-info d-flex  align-items-center cursor-pointer ">
                      <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-2  ">add</i>
                        Add Quotation
                    </button>
                </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        class="text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        S.No.
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9 ps-2"
                      >
                        Quotation Id
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Sender Name 
                      </th>
                      
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Receiver Name
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Purchase Order
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Purchase Invoice Id
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Purchase Invoice Number
                      </th>
                      
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Note
                      </th>
                      <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="quotation in all_quote" :key="quotation.sno">
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ quotation.id }}</span
                        >
                      </td>
                      
                      <td class="align-middle text-center">
                        <h6 class="mb-0 text-sm inline"> {{ quotation.quotation_id }}</h6>
                        
                        
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold border border-secondary py-1 px-2"
                          >{{ getSenderName(quotation.from_id) }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ quotation.to_name }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          > {{ quotation.po }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          > {{ quotation.pi }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          > {{ quotation.pi_no }}</span
                        >    
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold  cursor-pointer" style="max-width: 100px;"
                            data-bs-toggle="tooltip" data-bs-placement="right" :title="quotation.reason"
                            
                          >{{quotation.reason}}
                          <!--{{ quotation.note.length>10?quotation.note.substring(0,10)+'...':quotation.note }}--></span
                        >
                      </td> 
                      
                      <td class="align-middle text-center">
                        <a
                          href="javascript:;"
                          class="text-secondary font-weight-bold text-xs"
                          data-toggle="modal"
                          data-target="#quote_modal"
                          data-original-title="Edit user"
                          v-on:click="edit_quote(quotation.id)"
                        >
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                        </a>
                        <a
                          href="javascript:;"
                          class="text-secondary font-weight-bold text-xs"
                          data-toggle="modal" 
                          data-target="#deleteModal"
                          v-on:click="delete_quote(quotation.id)"
                        >
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                      
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
  </template>
  

<script>
import axios from 'axios';
export default {
    name: 'Quotation',
    data() {
        return {
            all_vendor :[
              {}
            ],
            all_quote:[

            ],
            selectedSender: '',
            quotations_form:
                {
                    id:'',
                    from_id:'',
                    to_id:'',
                    po:'',
                    pi:'',
                    pi_no :'',
                    reason:'',
                    quotation_id:'',
                },
            modal_form_method : 'Add'
        }
    },
    computed: {
        getSenderName() {
            const senderMap = new Map(this.all_vendor.map(vendor => [vendor.id, vendor.party_name]));
            return (id) => senderMap.get(id) || 'Unknown Sender';
        }
    },
    methods:{
        click_add(){
          this.modal_form_method = 'Add';
          this.quotations_form =
                {
                    id:'',
                    quotation_id:'Q0001',
                    from_id:'',
                    to_id:'',
                    po:'',
                    pi:'',
                    pi_no :'',
                    reason:''
                }
        },
        edit_quote(id){
          this.modal_form_method = 'Update';
          let key = this.all_quote.find((a)=>
            {
                return a.id==id;
            });
            console.log("key is "+this.all_quote.indexOf(key));
            key = this.all_quote.indexOf(key);
            try
            {
                if(key>=0 && key < this.all_quote.length)
                {

                    const data= this.all_quote[key];
                    if(data)
                    {
                        console.log("seleced hsn : ", data);
                        this.quotations_form.id = data.id;
                        this.quotations_form.quotation_id = data.quotation_id;
                        this.quotations_form.from_id = data.from_id;
                        this.quotations_form.to_id = data.to_id;
                        this.quotations_form.po = data.po;
                        this.quotations_form.pi = data.pi;
                        this.quotations_form.pi_no = data.pi_no;
                        this.quotations_form.reason = data.reason;
                        console.log("updated hsn : ", this.quotations_form);
                    }
                    else{
                        console.error("No object found at key:"+key);
                    }
                }   
                else{
                    console.error("invalid key:"+key);
                }
            }
            catch(error)
            {

                console.error("Error updating HSN form:", error);
            }
        },
        async get_vendor()
        {
          let result = await axios.get('https://arogya.softwarewabi.com/backend/public/api/Vendor_show');
          console.log(result.data.vendor);
          this.all_vendor = result.data.vendor;
        },
        async add_quote(method)
        {
          try {
              let result;
              if (method === 'Add') {
                result = await axios.post('https://arogya.softwarewabi.com/backend/public/api/Quotation_insert', this.quotations_form);
                this.get_quote()
              } else if (method === 'Update') {
                result = await axios.put(`https://arogya.softwarewabi.com/backend/public/api/Quotation_update/${this.quotations_form.id}`, this.quotations_form);
                this.get_quote()
              }
              console.log(result.data);
            } catch (error) {
              console.error('Error:', error.response ? error.response.data : error.message);
            }
        },
        async get_quote()
        {
          let result = await axios.get(`https://arogya.softwarewabi.com/backend/public/api/Quotation_show`);
          console.log(result.data.quotation);
          this.all_quote = result.data.quotation;
        },
        async delete_quote(id)
        {
          let result = await axios.delete(`https://arogya.softwarewabi.com/backend/public/api/Quotation_delete/${id}`);
          console.log(result.data.result)
          this.get_quote();
        }
    },
    async mounted(){
      this.get_vendor()
      this.get_quote()
    },
    watch: {
    selectedSender(newValue) {
      this.quotations_form.from_id = newValue;
    },
  },
}
</script>

<style>

</style>
<template>
    <div class="row m-2 p-2">
      <head>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
      </head>
      <!-- Modal FOR ADD HSN -->
      <!-- <div v-if="toggleModal">
        <H1>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium expedita rerum dignissimos eos molestiae autem est consequuntur dolor, reiciendis ipsam aliquam eaque possimus, delectus aspernatur, eligendi qui molestias dolorum sequi!
        </H1>
    
    </div> -->
      

    
        <!-- Modal -->
        <div class="modal fade " style="z-index: 999999;" id="store_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog p-0 " role="document">
                <div class="modal-content ">
                    <div class="modal-header">
                        <h3 class="modal-title w-100 text-center" id="exampleModalCenterTitle">{{ this.modal_form_method }} Store</h3>
                        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button> -->
                    </div>
                    <div class="modal-body">
                        <div class="container py-5">

                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xl">Party Name</label>
                                <input type="text" class="form-control  px-2 py-1 pe-auto" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter party name" v-model="store_form.party_name">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-lg">Franchise Type </label>
                                <select name="" id="" class="form-control px-2 py-1 pe-auto" v-model="store_form.type">
                                  <option value="">Select Franchise</option>
                                  <option value="arogya">Arogya</option>
                                  <option value="master_franchise">Master Franchise</option>
                                  <option value="franchise">Retail Franchise</option>
                                </select>
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-sm">Location</label>
                                <input type="text"  class="form-control px-2 py-1 pe-auto" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter location" v-model="store_form.location">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xs">GST No</label>
                                <input type="text"  class="form-control px-2 py-1 " id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter GST no"  v-model="store_form.gst_no">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xs">PAN No</label>
                                <input type="text"  class="form-control px-2 py-1 " id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter PAN no"  v-model="store_form.pan_no">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xs">Account No</label>
                                <input type="text"  class="form-control px-2 py-1 " id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account no"  v-model="store_form.bank_details.ac_no">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xs">ISFC code</label>
                                <input type="text"  class="form-control px-2 py-1 " id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter IFSC no"  v-model="store_form.bank_details.ifsc_code">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xs">Branch Address</label>
                                <input type="text"  class="form-control px-2 py-1 " id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Branch Address"  v-model="store_form.bank_details.branch_address">
                            </div>
                           
                            <div class="p-2">
                                <label for="exampleInputEmail1 text-xs">Branch Name</label>
                                <input type="text"  class="form-control px-2 py-1 " id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Branch Name"  v-model="store_form.bank_details.branch_name">
                            </div>
                           
                        </div>
                            
                    </div>
                    <div class="modal-footer justify-content-center">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-on:click="add_store(this.modal_form_method)" id="form_btn_add" type="button" data-dismiss="modal" class="btn bg-blue-dark text-white">{{ this.modal_form_method }} Store</button>
                    </div>
                </div>
            </div>
        </div>

  
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-blue-light shadow-success border-radius-lg pt-4 pb-3 row"
              >
                <h6 class="col-8 text-white text-capitalize ps-3">Stores</h6>
                <div class="col-4 d-flex justify-content-end align-item-center">
                  <button v-on:click="click_add" data-toggle="modal" data-target="#store_modal" class="badge badge-sm bg-white text-info d-flex  align-items-center cursor-pointer ">
                               
                        <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-2  ">add</i>
                        Add Store
                  </button>
                  <!-- <a-modal v-modal:open="open" title="Basic Modal" @ok="handleok">
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
                  </a-modal> -->
                </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        class="text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        S.No.
                      </th>
                      
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Party Name
                      </th>

                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9 ps-2"
                      >
                        Store Type
                      </th>
                      
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        GST No.
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        PAN No.
                      </th>
                      <th
                        class="text-center thead text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Bank Details
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Address
                      </th>
                      
                      <th
                        class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9"
                      >
                        Status
                      </th>
                      <th class="text-center text-uppercase text-secondary text-sm font-weight-bolder opacity-9">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(store, index) in all_stores" :key="store.id">
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ index+1 }}</span
                        >
                      </td>
                      
                      <td class="align-middle text-center">
                        <h6 class="mb-0 text-sm inline"> {{ store.party_name }}</h6>
                        
                        
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold border border-secondary py-1 px-2"
                          >{{ store.type }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{ store.gst_no }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          > {{ store.pan_no }}</span
                        >
                      </td> 
                      <td class="align-middle text-center">
                        <div class="row justify-content-center">

                          
                          <span class="text-secondary text-xs font-weight-bold text-wrap"
                          > {{  store.bank_details }}</span>
                        </div>
                      </td> 
                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          > {{ store.location }}</span
                        >    
                      </td> 
                      
                      <td class="align-middle text-center">
                        <span :class="is_approved_class(store.is_approved)" style="max-width: 100px;"
                            
                            
                          >{{ store.is_approved==1?"Approved":(store.is_approved==0)?"Not approved ":"" }}</span
                        >
                      </td> 
                      
                      <td class="align-middle text-center">
                        <a
                          href="javascript:;"
                          class="text-secondary font-weight-bold text-xs"
                          data-toggle="modal"
                          data-target="#store_modal"
                          data-original-title="Edit user"
                          v-on:click="edit_store(store.id)"
                        >
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-info p-1 rounded-circle mx-1">edit</i>
                          
                        </a>
                        <a
                          href="javascript:;"
                          class="text-secondary font-weight-bold text-xs"
                          data-toggle="tooltip"
                          data-original-title="Edit user"
                          v-on:click="delete_store(store.id)"
                        >
                          <i class="material-icons-round opacity-10 fs-6  text-white bg-danger p-1 rounded-circle mx-1">delete</i>
                      
                        </a>
                      </td>
                    </tr>
                    
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
  </template>
  

<script>
import axios from 'axios';
export default {
    name: 'Stores',
    data() {
        return {
              all_stores:[
                  // {
                  //     id:'1',
                  //     type:'Aarogya',
                  //     store_id:'A00001',
                  //     state:'New Delhi',
                  //     gst_no:'GSTIN124532',
                  //     contact_no:'9852147545',
                  //     location :'Address1',
                  //     pincode :'101010',
                  //     is_approved:'Active'
                  // },
                  // {
                  //     id:'2',
                  //     type:'Retail Franchise',
                  //     store_id:'A00002',
                  //     state:'Madhya Pradesh',
                  //     gst_no:'GSTIN956482',
                  //     contact_no:'9852147545',
                  //     location :'Address1',
                  //     pincode :'202020',
                  //     is_approved:'Inactive'
                  // },
                  // {
                  //     id:'3',
                  //     type:'Master Franchise',
                  //     store_id:'A00003',
                  //     state:'Uttar Pradesh',
                  //     gst_no:'GSTIN124532',
                  //     contact_no:'9852147545',
                  //     location :'Address1',
                  //     pincode :'303030',
                  //     is_approved:'Active'
                  // },
                  // {
                  //     id:'4',
                  //     type:'Retail Franchise',
                  //     store_id:'A00004',
                  //     state:'New Delhi',
                  //     gst_no:'GSTIN124532',
                  //     contact_no:'9852147545',
                  //     location :'Address4',
                  //     pincode :'141414',
                  //     is_approved:'Active'
                  // },
                  // {
                  //     id:'5',
                  //     type:'Master Franchise',
                  //     store_id:'A00005',
                  //     state:'New Delhi',
                  //     gst_no:'GSTIN9574685',
                  //     contact_no:'9852147545',
                  //     location :'Address1',
                  //     pincode :'121212',
                  //     is_approved:'Inactive'
                  // },
              
              ],
              store_form : {
                id : '',
                party_name : '',
                type : '',
                location : '',
                gst_no : '',
                pan_no : '',
                bank_details : {
                  ac_no : '',
                  ifsc_code : '',
                  branch_address : '',
                  branch_name : ''
                },
                is_approved : 0,
                parent_id : ''
              },
              modal_form_method : 'Add',
        }
    },
    methods:{
        is_approved_class(is_approved){
            return is_approved.toLowerCase()=='1'?'badge badge-lg bg-gradient-success':is_approved.toLowerCase()=='0'?'badge badge-lg bg-gradient-danger px-2':''
        },
        edit_store(id)
        {
          console.log('edit store called'); 
          this.modal_form_method   = 'Update';
          let key = this.all_stores.find((a) => 
          {
            return a.id == id;
          });
          key = this.all_stores.indexOf(key);
          try
          {
            if(key >= 0 && key < this.all_stores.length)
            {
              const data = this.all_stores[key];
              if(data)
              {
                console.log("selected store : ", data);
                console.log("selected store : ", data.bank_details);
                this.store_form.id = data.id;
                this.store_form.party_name = data.party_name;
                this.store_form.type = data.type;
                this.store_form.location = data.location;
                this.store_form.gst_no = data.gst_no;
                this.store_form.pan_no = data.pan_no;
                this.store_form.bank_details = data.bank_details;
                this.store_form.is_approved = data.is_approved;
                // this.parent_id
              } 
              else 
              {
                console.error("no object found at key : "+key);
              }
            }
            else 
            {
              console.error("invalid key : "+key);
            }
          }
          catch(error)
          {
            console.error("Error updating Stores form : ". error);
          }
        
        },
        click_add()
        {
          this.modal_form_method = 'Add';
          this.store_form.party_name = '';
          this.store_form.type = '';
          this.store_form.location = '';
          this.store_form.gst_no = '';
          this.store_form.pan_no = '';
          this.store_form.parent_id = '';
          this.store_form.bank_details.ac_no = '';
          this.store_form.bank_details.ifsc_code = '';
          this.store_form.bank_details.branch_address = '';
          this.store_form.bank_details.branch_name = '';
          this.store_form.is_approved = 0;
        },  
        async add_store(method)
        {
          if(method == 'Add')
          {
            let result = await axios.post(`https://arogya.softwarewabi.com/backend/public/api/franchiseinsert`, this.store_form);
            console.log(result.data);
            this.get_stores();
          }
          else if(method == 'Update')
          {
            let result = await axios.put(`https://arogya.softwarewabi.com/backend/public/api/franchiseupdate/${this.store_form.id}`, this.store_form);
            console.log(result.data);
            this.get_stores();
          }
        },
        async get_stores()
        {
          let result = await axios.get(`https://arogya.softwarewabi.com/backend/public/api/franchiseshow`);
          console.log(result.data.franchises);
          this.all_stores = result.data.franchises;
        },
        async delete_store(id)
        {
          let result = await axios.delete(`https://arogya.softwarewabi.com/backend/public/api/franchisedelete/${id}`);
          console.log(result.data.result)
          this.get_stores();
        }
    },
    async mounted()
    {
      this.get_stores();
    }
}
</script>

<style>

.thead{
  width: 15rem;
}
</style>